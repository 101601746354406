import gql from 'graphql-tag';
import React, {useEffect, useState} from 'react';
import {
  Query,
  QueryResult,
} from 'react-apollo';
import ApolloProvider from '../contextProviders/ApolloProvider';
import {
  generateStringLocationId,
  generateStringProductId,
} from '../graphQL/dataFetchers/Utils';
import {
  Location,
  Product,
  ProductClass,
  TradeDirection,
} from '../graphQL/graphQLTypes';
import Tooltip from '../viz/Tooltip';
import { ChartTitle } from '../viz/VizGrid';

const query = gql`
  query titleInformation($locationId: ID!, $productId: ID!) {
    location(id: $locationId) {
      id
      shortName
    }
    product(id: $productId) {
      id
      shortName
    }
  }
`;

interface SuccessResponse {
  location: {
    id: Location['id'],
    shortName: Location['shortName'],
  } | null;
  product: {
    id: Product['id'],
    shortName: Product['shortName'],
  } | null;
}

interface Variables {
  locationId: string;
  productId: string;
}

type Result = QueryResult<SuccessResponse, Variables>;

interface Props {
  country: number | undefined;
  product: number | undefined;
  tradeDirection: TradeDirection;
  year: number;
  productClass: ProductClass;
  setTitle?: (val: string) => void;
}

const ExploreGraphTitle = (props: Props) => {
  const {
    country, product, tradeDirection, year, productClass, setTitle,
  } = props;

  const [graphTitle, setGraphTitle] = useState<string>('');

  const updateGraphTitle = (val: string) => {
    if (val !== graphTitle) {
      setGraphTitle(val);
    }
  };

  useEffect(() => {
    if (setTitle !== undefined) {
      setTitle(graphTitle);
    }
  }, [graphTitle]);

  const tradeWord = (tradeDirection === TradeDirection.export) ? 'export' : 'import';
  const preposition = (tradeDirection === TradeDirection.export) ? 'to' : 'from';

  const locationId = country === undefined ? '' : generateStringLocationId(country);
  const productId = product === undefined ? '' : generateStringProductId({productClass, id: product});

  const variables: Variables = {locationId, productId};

  const renderProp = (result: Result) => {
    const {loading, error, data} = result;
    if (loading === true) {
      return null;
    } else if (error !== undefined) {
      console.error(error);
      return null;
    } else if (data !== undefined) {
      const { location, product: productData } = data;
      let title: string;
      if (location !== null && productData === null) {

        title = `Where did ${location.shortName} ${tradeWord} ${preposition} in ${year}?`;
      } else if (location === null && productData !== null) {

        const tradeWordPastTense = (tradeDirection === TradeDirection.export) ? 'exported' : 'imported';
        title = `Who ${tradeWordPastTense} ${productData.shortName} in ${year}?`;
      } else if (location !== null && productData !== null) {

        title = `Where did ${location.shortName} ${tradeWord} ${productData.shortName} ${preposition} in ${year}?`;
      } else {
        title = '';
      }
      updateGraphTitle(title);
      return (
        <Tooltip
          explanation={title}
          title={title}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <ChartTitle>
      <ApolloProvider>
        <Query
          query={query}
          children={renderProp}
          variables={variables}
        />
      </ApolloProvider>
    </ChartTitle>
  );
};

export default ExploreGraphTitle;
