import {
  ProductClass,
} from '../../graphQL/graphQLTypes';
import getYearlyDataCache, {
  IBaseState,
} from '../../sharedComponents/yearlyDataCache';
import {
  apiBaseURL,
  fetchJSON,
  ICPYDatum,
} from '../../Utils';
import {
  IWorkerRootState,
} from '../workerRootState';

export type IState = IBaseState<ICPYDatum>;

interface IHashInput {
  productClass: ProductClass;
  product: number;
}

interface IAPIResponse {
  data: ICPYDatum[];
}

export const defaultHashFunction = ({productClass, product}: IHashInput) => `${productClass}_${product}`;

const FETCH_BEGIN = 'COUNTRY_PRODUCT_YEAR_BY_PRODUCT_FETCH_BEGIN';
const FETCH_SUCCESS = 'COUNTRY_PRODUCT_YEAR_BY_PRODUCT_FETCH_SUCCESS';
const FETCH_FAIL = 'COUNTRY_PRODUCT_YEAR_BY_PRODUCT_FETCH_FAIL';
const FETCH_IF_NEEDED = 'COUNTRY_PRODUCT_YEAR_BY_PRODUCT_FETCH_IF_NEEDED';

const {
  reducer,
  fetchDataEpic,
  fetchIfNeeded,
  getDataStatusSelector,
  getYearsStatusSelector,
} = getYearlyDataCache<
  IWorkerRootState,
  ICPYDatum,
  typeof FETCH_IF_NEEDED,
  typeof FETCH_BEGIN,
  typeof FETCH_SUCCESS,
  typeof FETCH_FAIL,
  IHashInput,
  IAPIResponse
>({
  fetchIfNeededName: FETCH_IF_NEEDED,
  fetchBeginName: FETCH_BEGIN,
  fetchSuccessName: FETCH_SUCCESS,
  fetchFailName: FETCH_FAIL,
  outerHashFunction: defaultHashFunction,
  getCacheFromRootState: (rootState: IWorkerRootState) => rootState.countryProductYearByProduct,
  getFetchPromise: ({productClass, product}: IHashInput) => {
    const productClassPhrase = (productClass === ProductClass.HS) ? 'hs_product' : 'sitc_product';
    return fetchJSON<IAPIResponse>(`${apiBaseURL}/data/${productClassPhrase}/${product}/exporters/?level=country`);
  },
  getDataFromAPIResponse: ({data}: IAPIResponse) => data,
});

export default reducer;
export {fetchDataEpic, fetchIfNeeded, getDataStatusSelector, getYearsStatusSelector};
