import getYearlyDataCache, {
  IBaseState,
} from '../../sharedComponents/yearlyDataCache';
import {
  apiBaseURL,
  fetchJSON,
  ICCYDatum,
} from '../../Utils';
import {
  IWorkerRootState,
} from '../workerRootState';

export type IState = IBaseState<ICCYDatum>;

interface IHashInput {
  country: number;
}

interface IAPIResponse {
  data: ICCYDatum[];
}

export const defaultHashFunction = ({country}: IHashInput) => country.toString();

const FETCH_BEGIN = 'COUNTRY_COUNTRY_YEAR_BY_COUNTRY_FETCH_BEGIN';
const FETCH_SUCCESS = 'COUNTRY_COUNTRY_YEAR_BY_COUNTRY_FETCH_SUCCESS';
const FETCH_FAIL = 'COUNTRY_COUNTRY_YEAR_BY_COUNTRY_FETCH_FAIL';
const FETCH_IF_NEEDED = 'COUNTRY_COUNTRY_YEAR_BY_COUNTRY_FETCH_IF_NEEDED';

const {
  reducer,
  fetchDataEpic,
  fetchIfNeeded,
  getDataStatusSelector,
  getYearsStatusSelector,
} = getYearlyDataCache<
  IWorkerRootState,
  ICCYDatum,
  typeof FETCH_IF_NEEDED,
  typeof FETCH_BEGIN,
  typeof FETCH_SUCCESS,
  typeof FETCH_FAIL,
  IHashInput,
  IAPIResponse
>({
  fetchIfNeededName: FETCH_IF_NEEDED,
  fetchBeginName: FETCH_BEGIN,
  fetchSuccessName: FETCH_SUCCESS,
  fetchFailName: FETCH_FAIL,
  outerHashFunction: defaultHashFunction,
  getCacheFromRootState: (rootState: IWorkerRootState) => rootState.countryCountryYearByCountry,
  getFetchPromise: ({country}: IHashInput) => fetchJSON<IAPIResponse>(
    `${apiBaseURL}/data/location/${country}/partners/?level=country`,
  ),
  getDataFromAPIResponse: ({data}: IAPIResponse) => data,
});

export default reducer;
export {fetchDataEpic, fetchIfNeeded, getDataStatusSelector, getYearsStatusSelector};
