import {
  ProductClass,
} from '../../graphQL/graphQLTypes';
import getYearlyDataCache, {
  IBaseState,
} from '../../sharedComponents/yearlyDataCache';
import {
  apiBaseURL,
  fetchJSON,
  ICCPYDatum,
} from '../../Utils';
import {
  IWorkerRootState,
} from '../workerRootState';

export type IState = IBaseState<ICCPYDatum>;

interface IHashInput {
  productClass: ProductClass;
  country: number;
  product: number;
}

interface IAPIResponse {
  data: ICCPYDatum[];
}

export const defaultHashFunction =
  ({productClass, country, product}: IHashInput) => `${productClass}_${country}_${product}`;

const FETCH_BEGIN = 'COUNTRY_COUNTRY_PRODUCT_YEAR_BY_COUNTRY_PRODUCT_FETCH_BEGIN';
const FETCH_SUCCESS = 'COUNTRY_COUNTRY_PRODUCT_YEAR_BY_COUNTRY_PRODUCT_FETCH_SUCCESS';
const FETCH_FAIL = 'COUNTRY_COUNTRY_PRODUCT_YEAR_BY_COUNTRY_PRODUCT_FETCH_FAIL';
const FETCH_IF_NEEDED = 'COUNTRY_COUNTRY_PRODUCT_YEAR_BY_COUNTRY_PRODUCT_FETCH_IF_NEEDED';

const {
  reducer,
  fetchDataEpic,
  fetchIfNeeded,
  getDataStatusSelector,
  getYearsStatusSelector,
} = getYearlyDataCache<
  IWorkerRootState,
  ICCPYDatum,
  typeof FETCH_IF_NEEDED,
  typeof FETCH_BEGIN,
  typeof FETCH_SUCCESS,
  typeof FETCH_FAIL,
  IHashInput,
  IAPIResponse
>({
  fetchIfNeededName: FETCH_IF_NEEDED,
  fetchBeginName: FETCH_BEGIN,
  fetchSuccessName: FETCH_SUCCESS,
  fetchFailName: FETCH_FAIL,
  outerHashFunction: defaultHashFunction,
  getCacheFromRootState: (rootState: IWorkerRootState) => rootState.countryCountryProductYearByCountryProduct,
  getFetchPromise: ({productClass, country, product}: IHashInput) => {
    const productClassPhrase = (productClass === ProductClass.HS) ?
                                'hs_product' : 'sitc_product';
    return fetchJSON<IAPIResponse>(
      `${apiBaseURL}/data/location/${country}/partners_by_${productClassPhrase}/${product}/?level=country`,
    );
  },
  getDataFromAPIResponse: ({data}: IAPIResponse) => data,
});

export default reducer;
export {fetchDataEpic, fetchIfNeeded, getDataStatusSelector, getYearsStatusSelector};
